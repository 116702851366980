<template>
  <div>
    <b-row cols="1">
      <b-col>
        <b-card>
          <div class="d-flex justify-content-between">
            <b-card-title class="mr-50">สถานะ : {{ statusAppMaintenance ? 'ปิดปรับปรุง' : 'ปกติ' }}</b-card-title>
            <b-avatar :variant="statusAppMaintenance ? 'danger' : 'success'" size="45">
              <feather-icon size="20" icon="CloudIcon" />
            </b-avatar>
          </div>
          <b-card-text>สถานะการปิดปรับปรุงแอป</b-card-text>
          <b-alert variant="danger" :show="statusAppMaintenanceError !== ''" class="mt-1">
            <div class="alert-body">
              <span>
                {{ statusAppMaintenanceError }}
              </span>
            </div>
          </b-alert>
          <!-- <button-widget text="เปิดใช้งาน" block variant="danger" class="mt-2" /> -->
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <div class="d-flex justify-content-between">
            <b-card-title class="mr-50">สถานะ : {{ statusReloadConfig ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }}</b-card-title>
            <b-avatar :variant="statusReloadConfig ? 'success' : 'secondary'" size="45">
              <feather-icon size="20" icon="SmartphoneIcon" />
            </b-avatar>
          </div>
          <b-card-text>
            แอปโหลด config ใหม่อีกครั้ง เมื่อผู้ใช้งานพับแอปแล้วกลับเข้ามาใช้งาน
            <small class="text-danger">
              (ควรเปิดใช้เมื่อทำการปิดปรุงปรุงระบบเพื่อป้องกันผู้ใช้เข้าใช้งาน ระหว่างการปรับปรุง)
            </small>
          </b-card-text>
          <button-widget
            v-if="!statusReloadConfigError"
            :variant="statusReloadConfig ? 'danger' : 'success'"
            :loading="statusReloadConfigLoading"
            :text="statusReloadConfig ? 'ปิดใช้งาน' : 'เปิดใช้งาน'"
            block
            class="mt-2"
            @click="changeStatusReloadConfig"
          />
          <b-alert variant="danger" :show="statusReloadConfigError !== ''" class="mt-1">
            <div class="alert-body">
              <span>
                {{ statusReloadConfigError }}
              </span>
            </div>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <button-widget
      text="สั่ง Pusher ให้แอปโหลด config ใหม่"
      block
      variant="primary"
      :loading="btnSendPusherLoading"
      @click="sendPusherReloadConfig"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      statusReloadConfig: false,
      statusReloadConfigError: '',
      statusReloadConfigLoading: false,
      statusAppMaintenance: false,
      statusAppMaintenanceError: '',
      statusAppMaintenanceLoading: false,
      btnSendPusherLoading: false,
    }
  },
  created() {
    this.getStatusAppMaintenance()
    this.getStatusReloadConfig()
  },
  methods: {
    async getStatusReloadConfig() {
      this.statusReloadConfigLoading = true
      const resp = await this.api.get('/api/application/get-status-clear-cache').catch(() => null)
      if (typeof resp === 'boolean') {
        this.statusReloadConfig = resp
        this.statusReloadConfigError = ''
      } else {
        this.statusReloadConfigError = 'พบข้อผิดพลาด ไม่สามารถตรวจสอบได้ ขอมูลที่แสดงอาจจะไม่ถูกต้อง'
      }
      this.statusReloadConfigLoading = false
    },
    async changeStatusReloadConfig() {
      const isConfirm = await this.gCheckConfirmV1('ยืนยันเปิดการใช้งาน')
      if (!isConfirm) return
      this.statusReloadConfigLoading = true
      const resp = await this.api
        .postV2('/api/admin/application/change-status-clear-cache', { status: !this.statusReloadConfig }, this)
        .catch(() => null)
      if (resp && resp.code === 200) {
        this.statusReloadConfig = !this.statusReloadConfig
      } else {
        this.gDisplayToast('พบข้อผิดพลาด', 'ไม่สามารถเปิดหรือปิดการใช้งานได้ โปรดติดต่อผู้ดูแลระบบ', 'danger')
      }
      this.statusReloadConfigLoading = false
    },
    async getStatusAppMaintenance() {
      const resp = await this.api
        .getV2('/api/application/config?key=1134f13a-4cba-4bc0-bb18-df7d70d28c92')
        .catch(() => null)
      // console.log('getStatusAppMaintenance', resp)

      if (resp && typeof resp === 'object' && 'app_maintenance' in resp) {
        this.statusAppMaintenance = resp?.app_maintenance
        this.statusAppMaintenanceError = ''
      } else {
        this.statusAppMaintenanceError = 'พบข้อผิดพลาด ไม่สามารถตรวจสอบได้ ขอมูลที่แสดงอาจจะไม่ถูกต้อง'
      }
    },
    async sendPusherReloadConfig() {
      const isConfirm = await this.gCheckConfirmV1('ยืนยันการสั่งให้โหลด config ใหม่')
      if (!isConfirm) return
      this.btnSendPusherLoading = true
      const resp = await this.api.postV2('/api/admin/application/send-pusher-clear-cache', {}, this)
      if (resp && resp.code === 200) {
        this.gDisplayToast('ดำเนินการสำเร็จ')
      } else {
        this.gDisplayToast('พบข้อผิดพลาด', 'ไม่สามารถสั่งใช้งานได้ โปรดติดต่อผู้ดูแลระบบ', 'danger')
      }
      this.btnSendPusherLoading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
